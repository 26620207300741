
import { RequestPaging } from '@/utils/util'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MaterialItem from '@/components/material-select/material.vue'
@Component({
  components: {
    MaterialItem
  }
})
export default class Material extends Vue {
    activeName = 'image'
    paneLists = [
      {
        label: '图片',
        name: 'image',
        type: 'image'
      },
      {
        label: '视频',
        name: 'video',
        type: 'video'
      }
    ]

    pager = new RequestPaging()

    getList () {}
}
